import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Box,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import InputController from 'components/Form/InputController';
import { daysOfWeek, FileExcelValid, ModalType, Roles, TYPE_AUTOCALL } from 'constants/common';
import { toast } from 'components/Toast';
import {
  CampaignHotlineSuperAdminFormValidate,
  CampaignHotlineSuperAdminFormValidateAdmin,
  HotlineAutodialerFormValidate,
  UpdateCampaignHotlineSuperAdminFormValidate,
  UpdateCampaignHotlineSuperAdminFormValidateAdmin,
} from 'utils/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { importPhoneCampagin, useQueryGetHotlineTemplateAssign } from 'services/ips';
import SelectController from 'components/Form/SelectController';
import { useQueryGetCompany } from 'services/company';
import { CookieStorage } from 'utils/cookie-storage';
import { mappingOptionSelect } from 'utils/mapping';
import { useCreateHotlineVoiceCampaignDtelMutation, useUpdateCampaignMutation } from 'services/campaign';
import { useUserState } from 'context/UserContext';
import isEmpty from 'lodash/isEmpty';

const FormCampaign = ({ companyDetail, isOpen, onClose, refetch }) => {
  const { userInfo } = useUserState();
  const cancelRef = React.useRef();
  const updateCampaignMutation = useUpdateCampaignMutation();
  const createHotlineVoiceCampaignMutation = useCreateHotlineVoiceCampaignDtelMutation();
  const inputImportRef = React.useRef();
  const { control, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(
      // isEmpty(companyDetail)
      //   ? userInfo?.role === Roles.SUPER_ADMIN
      //     ? CampaignHotlineSuperAdminFormValidate
      //     : CampaignHotlineSuperAdminFormValidateAdmin
      //   : userInfo?.role === Roles.SUPER_ADMIN
      //   ? UpdateCampaignHotlineSuperAdminFormValidate
      //   : UpdateCampaignHotlineSuperAdminFormValidateAdmin
      HotlineAutodialerFormValidate
    ),
  });
  const isLoggedIn = CookieStorage.isAuthenticated();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [errorFile, setErrorFile] = useState('');
  const [file, setFile] = useState(null);

  const { data: hotlineTemplate } = useQueryGetHotlineTemplateAssign(
    { company_id: selectedCompany?.value || userInfo?.company || companyDetail?.company?._id, type: TYPE_AUTOCALL.AUTO_CALL_VOICE },
    { enabled: isLoggedIn && (!!selectedCompany || !!userInfo?.company || !!companyDetail?.company?._id) }
  );
  const { data: company } = useQueryGetCompany({}, { enabled: isLoggedIn && userInfo?.role === Roles.SUPER_ADMIN });

  const handleSuccess = message => {
    toast.showMessageSuccess(message);
    refetch?.();
    onClose(ModalType.Add);
  };

  const onSubmit = async values => {
    if (isEmpty(companyDetail)) {
      if (!file) {
        setErrorFile('Vui lòng chọn file số điện thoại');
        return;
      }
      createHotlineVoiceCampaignMutation.mutate(
        {
          campaign_name: values?.campaign_name,
          concurrent_calc: Number(values?.concurrent_calc),
          company_id: values?.company_id?.value,
        },
        {
          onSuccess: async res => {
            if (file && res.data?._id) {
              const formData = new FormData();
              formData.append('phoneFile', file);
              formData.append('campaign_id', res.data?._id);
              await importPhoneCampagin(formData)
                .then(() => {
                  console.log('Success');
                })
                .catch(() => {
                  toast.showMessageError('Tải lên số điện thoại thất bại');
                  return;
                });
            }
            handleSuccess(`Tạo gọi voice chiến dịch thành công`);
          },
        }
      );
      return;
    }
    updateCampaignMutation.mutate(
      {
        campaign_name: values?.campaign_name,
        concurrent_calc: Number(values?.concurrent_calc),
        campaign_id: companyDetail?._id,
      },
      {
        onSuccess: () => handleSuccess(`Cập nhật gọi voice chiến dịch thành công`),
      }
    );
  };

  const handleImportPhone = async e => {
    if (e?.target?.files?.[0]) {
      const phoneFile = e.target.files[0];
      const extensionFile = phoneFile?.name?.split('.')?.pop();
      if (FileExcelValid.includes(extensionFile)) {
        setFile(phoneFile);
        setErrorFile('');
        return;
      }
      setFile(null);
      setErrorFile('Chỉ hỗ trợ tải lại file định dạng .xlsx, xls');
      return;
    }
    setFile(null);
    setErrorFile('Vui lòng choọn file số điện thoại');
  };

  useEffect(() => {
    let matchingDays = [];
    let startTime = null;
    let endTime = null;
    if(companyDetail?.schedules?.[0]) {
      matchingDays = daysOfWeek.filter((day) => {
        const startTimeKey = `${day.value}_start_time`;
        const endTimeKey = `${day.value}_end_time`;
        if(companyDetail?.schedules?.[0]?.[startTimeKey] !== 28800000000000) {
          startTime = companyDetail?.schedules?.[0]?.[startTimeKey];
        }
        if(companyDetail?.schedules?.[0]?.[endTimeKey] !== 75600000000000) {
          endTime = companyDetail?.schedules?.[0]?.[endTimeKey];
        }
        return (
          companyDetail?.schedules?.[0]?.[startTimeKey] !== 28800000000000 ||
          companyDetail?.schedules?.[0]?.[endTimeKey] !== 75600000000000
        );
      });
    }
    if (!isEmpty(companyDetail)) {
      const template = hotlineTemplate?.data?.find(item => companyDetail?.hotlineTemplate?._id === item?._id);
      reset({
        campaign_name: companyDetail?.campaign_name,
        concurrent_calc: companyDetail?.concurrent_calc,
        template_id: {
          label: template?.template_name,
          value: template?._id,
        },
        company_id: {
          label: companyDetail?.company?.name,
          value: companyDetail?._id,
        },
      });
    }
  }, [companyDetail, hotlineTemplate?.data]);
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose(ModalType.Add);
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent maxW={'600px'}>
          <AlertDialogHeader textTransform="uppercase">
            {isEmpty(companyDetail) ? 'Tạo' : 'Chỉnh sửa'} thông tin gọi voice chiến dịch
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <InputController
              control={control}
              isRequired
              name="campaign_name"
              label="Tên chiến dịch"
              styleContainer={{
                marginBottom: '12px',
              }}
            />
            <InputController type="number" control={control} isRequired name="concurrent_calc" label="Số lượng cuộc gọi đồng thời" />
            {userInfo?.role === Roles.SUPER_ADMIN && (
              <SelectController
                isDisabled={!isEmpty(companyDetail)}
                control={control}
                name="company_id"
                label="Company"
                options={mappingOptionSelect(company?.data, 'name', '_id')}
                onChange={e => {
                  setSelectedCompany(e);
                }}
                isRequired
                styleContainer={{ pt: 4 }}
              />
            )}
            {isEmpty(companyDetail) && (
              <>
                <Box mt={5} display={'flex'} alignItems={'center'} gap={5}>
                  <Text>File số điện thoại:</Text>
                  <Text>{file?.name}</Text>
                  <Button
                    onClick={() => {
                      inputImportRef?.current?.click();
                    }}
                  >
                    Chọn file
                  </Button>
                  <input type="file" hidden ref={inputImportRef} onChange={handleImportPhone} />
                </Box>
                {errorFile && (
                  <Text color="red.500" fontSize={12} mt={2}>
                    {errorFile}
                  </Text>
                )}
              </>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose(ModalType.Add);
              }}
            >
              Hủy
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createHotlineVoiceCampaignMutation.isPending} onClick={handleSubmit(onSubmit)}>
              {isEmpty(companyDetail) ? 'Tạo' : 'Chỉnh sửa'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default FormCampaign;
